progress[value] {
    width: 100%;
    height: 30px;
    margin-left: -20px;
  }
.stats {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;

  }
  .stat {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .png-bg {
    /* background-color: #bfd9f9a3; */
    width: 70px;
    height: 40px;
    border-radius: 70px;
    z-index: 1;
    justify-content: center;
  }
  .png {
    position: absolute;
    width: 40px;
    z-index: 1;
  }
  span {
    padding: 3px;
  }

  .small-png {
    width: 55px;
    left: 25px;
  }