#work-content {
    float: left;
    margin-bottom: 30px;
    width: 100%;
}

* {
    text-decoration: none;
    list-style-type: none;
}
a:link {
    color: #232323;
}

a:visited {
    color: rgb(0, 0, 0);
  }

  a:hover {
    color: #9200bd;
  }



.spin_splat {
    animation: spin 20s linear infinite;
    position: absolute;
    width: 120px;

    margin-right: 131px;
    margin-right: -200px;
    margin-top: -40px;

}

.spin_splat:hover {
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}



@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

.hfeed {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

  

.post-title {
    margin-top: -20px;
    background-color: #fdfaf7a1;
    position: sticky;
    font-family: 'Helvetica', serif;
    letter-spacing: 0.03rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 220px;
}
.proj_box {
    display: flex;
    justify-content: center;
    width: 220px;
}
.byline {
    margin: 0 0 0 0;
    font-size: 0.9166666666666667em;
    color: #aaa;
    line-height: 1.75em;
    word-spacing: 2px;
}

.entry-content {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font: 1.04em 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #555;
    margin-bottom: 3rem;

}

.single-thumbnail {
    margin-bottom: 40px;
}

.tag-title {
    background: none;
    background-color: white;
}



@media (max-width: 523px) {
    .hfeed {
        justify-items: center;

    }

}