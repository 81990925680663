#masthead {
    width: 100%;
    float: left;
    margin-bottom: 50px;
}

#author-bio {
    float: left;
    width: 27.65957446808511%;
}

#header-banner {
    width: 68.08510638297872%;
    float: right;
    margin-top: 1px;
}

#img-jeff {
    width: -webkit-fill-available;
}

@media (max-width: 820px) {
    #masthead {
        width: 100%;
        float: left;
    }
    #author-bio {
        width: 100%;
        margin-bottom: 2.5rem;
    }

    #header-banner {
        width: 100%;
    }
}

